<template>
  <registry-card
    v-if="show"
    :registry-id="registryId"
    :record-id="recordId"
    :card-id="cardId"
    :readonly="readOnly"
    :initial-data="initialData"
    @recordAdded="addCallback"
    @recordEdited="updateCallback"
  ></registry-card>
</template>

<script>
import RegistryCard from '@/components/RegistryCard/index'
import mixin from '@/components/InterfaceEditor/components/mixins'
export default {
  name: 'a-frame',
  components: { RegistryCard },
  mixins: [mixin],
  data () {
    return {
      cardId: null,
      registryId: null,
      recordId: null,
      readOnly: false,
      updateCallback: () => {},
      addCallback: () => {},
      initialData: {},
      show: false
    }
  },
  methods: {
    openCard ({ cardId, registryId, recordId, readOnly, initialData = {}, updateCallback = () => {}, addCallback = () => {} }) {
      this.show = false
      this.cardId = cardId
      this.recordId = recordId
      this.registryId = registryId
      this.addCallback = addCallback
      this.updateCallback = updateCallback
      this.initialData = initialData
      this.readOnly = readOnly
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>

<style scoped>

</style>
